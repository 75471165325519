<template>
  <div
    class="base-modal fixed left0 top0 wh100 flex-center z99"
    @click.self="closeModal"
  >
    <div id="modal-content">
      <slot />
    </div>
  </div>
</template>

<script setup>
  const emits = defineEmits(['close']);
  function closeModal () {
    emits('close');
  }
</script>

<style lang="scss">
  .base-modal {
    @media only screen and (min-width: 0) {
      background-color: rgba(0, 0, 0, 0.7);
      > div {
        max-width: 70%;
      }
    }
  }
</style>
