import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    shareUrl: null
  },
  mutations: {
    SET_SHARE_URL (state, url) {
      state.shareUrl = url;
    }
  },
  actions: {
    async uploadChunk ({ commit }, videoFile) {
      const formData = new FormData();
      const webmBlob = new Blob([videoFile], { type: 'video/webm' });
      formData.append('chunk', webmBlob, 'video.webm');
      try {
        const { data } = await axios.post('/api/convert', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        commit('SET_SHARE_URL', data.shareUrl);
      } catch (error) {
        console.error('Error uploading video:', error);
        throw error;
      }
    },
    async sendAnalytics ({ commit }, analytics) {
      try {
        await axios.post('/api/analytics', {
          analytics
        });
      } catch (error) {
        console.error('Error sending analytics:', error);
      }
    }
  }
});
