import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import rangePlugin from './plugins/range';
import './css/normalize.scss';
import './css/helper-classes.scss';
import './css/typography.scss';
import './css/base.scss';

createApp(App).use(store).use(router).use(rangePlugin).mount('#app');
